<script>
import accountMixin from "@/mixins/accounts.js";
import View from "@/views/View";
import LoadSections from "@/mixins/load-sections";
export default {
  name: "LoanApplication",
  metaInfo: { title: "Personal Information" },
  extends: View,
  mixins: [accountMixin, LoadSections(["loan-form", "info"])],
  props: {
    id: {
      type: String,
      default: "about",
    },
  },
};
</script>
